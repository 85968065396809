import { string } from 'prop-types'

export default function IconSocialPinterest({ color, height, width }) {
  return (
    <svg height={height} viewBox="0 0 32 32" width={width}>
      <path
        d="M28 16C28 22.629 22.629 28 16 28C14.7613 28 13.571 27.8113 12.4484 27.4629C12.9371 26.6645 13.6677 25.3581 13.9387 24.3177C14.0839 23.7565 14.6839 21.4629 14.6839 21.4629C15.0758 22.2081 16.2177 22.8419 17.4323 22.8419C21.0516 22.8419 23.6597 19.5129 23.6597 15.3758C23.6597 11.4129 20.4226 8.44677 16.2613 8.44677C11.0839 8.44677 8.33065 11.921 8.33065 15.7097C8.33065 17.471 9.26936 19.6629 10.7645 20.3597C10.9919 20.4661 11.1129 20.4177 11.1661 20.2C11.2048 20.0355 11.4081 19.2177 11.5 18.8403C11.529 18.7194 11.5145 18.6129 11.4177 18.4968C10.929 17.8919 10.5323 16.7887 10.5323 15.7581C10.5323 13.1113 12.5355 10.5516 15.9516 10.5516C18.8984 10.5516 20.9645 12.5597 20.9645 15.4339C20.9645 18.6806 19.3242 20.9306 17.1903 20.9306C16.0145 20.9306 15.129 19.9581 15.4145 18.7629C15.7532 17.3355 16.4065 15.7968 16.4065 14.7661C16.4065 13.8468 15.9129 13.0774 14.8871 13.0774C13.6823 13.0774 12.7145 14.321 12.7145 15.9903C12.7145 17.0548 13.0726 17.771 13.0726 17.771C13.0726 17.771 11.8871 22.7935 11.6694 23.7323C11.4274 24.7677 11.5242 26.229 11.6258 27.1774C7.16452 25.4306 4 21.0855 4 16C4 9.37097 9.37097 4 16 4C22.629 4 28 9.37097 28 16Z"
        fill={color}
      />
    </svg>
  )
}

IconSocialPinterest.propTypes = {
  color: string.isRequired,
  height: string,
  width: string,
}
