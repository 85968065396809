import { string } from 'prop-types'

export default function IconArrowLeft({ color, height, width }) {
  return (
    <svg height={height} viewBox="0 0 512 512" width={width}>
      <polygon fill={color} points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " />
    </svg>
  )
}

IconArrowLeft.propTypes = {
  color: string.isRequired,
  height: string,
  width: string,
}
