import { string } from 'prop-types'

export default function IconSocialRss({ color, height, width }) {
  return (
    <svg height={height} viewBox="0 0 32 32" width={width}>
      <path
        d="M28 26.9091C28 27.5116 27.5116 28 26.9091 28C26.3066 28 25.8182 27.5116 25.8182 26.9091C25.8182 15.4616 16.5384 6.18182 5.09091 6.18182C4.48842 6.18182 4 5.6934 4 5.09091C4 4.48842 4.48842 4 5.09091 4C17.7433 4 28 14.2567 28 26.9091ZM21.4545 26.9091C21.4545 27.5116 20.9661 28 20.3636 28C19.7611 28 19.2727 27.5116 19.2727 26.9091C19.2727 19.0764 12.9236 12.7273 5.09091 12.7273C4.48842 12.7273 4 12.2389 4 11.6364C4 11.0339 4.48842 10.5455 5.09091 10.5455C14.1285 10.5455 21.4545 17.8715 21.4545 26.9091ZM14.9091 26.9091C14.9091 27.5116 14.4207 28 13.8182 28C13.2157 28 12.7273 27.5116 12.7273 26.9091C12.7273 22.6916 9.30836 19.2727 5.09091 19.2727C4.48842 19.2727 4 18.7843 4 18.1818C4 17.5793 4.48842 17.0909 5.09091 17.0909C10.5133 17.0909 14.9091 21.4867 14.9091 26.9091ZM5.63636 28C4.73263 28 4 27.2674 4 26.3636C4 25.4599 4.73263 24.7273 5.63636 24.7273C6.5401 24.7273 7.27273 25.4599 7.27273 26.3636C7.27273 27.2674 6.5401 28 5.63636 28Z"
        fill={color}
      />
    </svg>
  )
}

IconSocialRss.propTypes = {
  color: string.isRequired,
  height: string,
  width: string,
}
