import { string } from 'prop-types'

export default function IconSocialTikTok({ height, width }) {
  return (
    <svg fill="none" height={height} viewBox="0 0 32 32" width={width}>
      <path
        d="M23.3387 8.81059C22.0455 7.96779 21.1131 6.6187 20.8217 5.04688C20.7591 4.70735 20.7242 4.35759 20.7242 4H16.5981L16.5915 20.5358C16.5222 22.3875 14.998 23.8739 13.1299 23.8739C12.549 23.8739 12.0024 23.7288 11.5208 23.4754C10.4167 22.8945 9.66122 21.7374 9.66122 20.4058C9.66122 18.4932 11.2174 16.937 13.1293 16.937C13.4863 16.937 13.8289 16.996 14.1528 17.0971V12.8849C13.8174 12.8392 13.4767 12.8109 13.1293 12.8109C8.94182 12.8109 5.5351 16.2177 5.5351 20.4058C5.5351 22.9751 6.81856 25.2489 8.77747 26.6239C10.011 27.4901 11.5118 28 13.1299 28C17.3174 28 20.7242 24.5933 20.7242 20.4058V12.0205C22.3424 13.1817 24.3253 13.8662 26.4649 13.8662V9.74009C25.3127 9.74009 24.2392 9.39755 23.3387 8.81059Z"
        fill="black"
      />
    </svg>
  )
}

IconSocialTikTok.propTypes = {
  height: string,
  width: string,
}
