function gaNotify(label, action, interactive) {
  const { ga } = window

  // Ready callback will be called when GA in ready passing default tracker as argument.
  ga((tracker) => {
    let definedTracker = tracker
    // If no default tracker is provided - try to use the first one we encounter.
    if (!definedTracker) {
      const trackers = ga.getAll()
      if (!trackers || !trackers.length) {
        return
      }
      ;[definedTracker] = trackers
    }

    definedTracker.send({
      hitType: 'event',
      eventCategory: 'flowbox',
      eventLabel: label,
      eventAction: action,
      nonInteraction: !interactive,
    })
  })
}

function registerEvent(label, action, interactive = false) {
  const { ga, gtag, dataLayer /* , _paq, pa */ } = window

  if (typeof ga === 'function') {
    // GA - Google Analytics
    gaNotify(label, action, interactive)
  } else if (typeof gtag === 'function') {
    // GTM - Google Tag Manager (obsolete)
    gtag('event', `${label}_${action}`, {
      category: 'flowbox',
      interactive,
    })
  } else if (dataLayer && typeof dataLayer.push === 'function') {
    // GTM - Google Tag Manager (modern)
    dataLayer.push({
      event: `${label}_${action}`,
      category: 'flowbox',
      interactive,
    })
  }

  // Matomo
  /* if (_paq && typeof _paq.push === 'function') {
    _paq.push(['trackEvent', 'flowbox', action, label])
  } */

  // Piano
  /* if (pa && typeof pa.sendEvent === 'function') {
    pa.sendEvent(`${label}_${action}`, {
      category: 'flowbox',
      interactive,
    })
  } */
}

export default registerEvent
