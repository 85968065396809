import { string } from 'prop-types'

export default function IconSocialYoutube({ height, width }) {
  return (
    <svg fill="none" height={height} viewBox="0 0 32 32" width={width}>
      <path
        d="M29.6887 9.12932C29.3601 7.89755 28.3919 6.92745 27.1627 6.59823C24.9346 6 16 6 16 6C16 6 7.06552 6 4.83734 6.59823C3.60807 6.9275 2.63992 7.89755 2.31132 9.12932C1.71429 11.362 1.71429 16.0202 1.71429 16.0202C1.71429 16.0202 1.71429 20.6784 2.31132 22.9111C2.63992 24.1429 3.60807 25.0726 4.83734 25.4018C7.06552 26 16 26 16 26C16 26 24.9345 26 27.1627 25.4018C28.3919 25.0726 29.3601 24.1429 29.6887 22.9111C30.2857 20.6784 30.2857 16.0202 30.2857 16.0202C30.2857 16.0202 30.2857 11.362 29.6887 9.12932ZM13.0779 20.2495V11.7909L20.5454 16.0203L13.0779 20.2495Z"
        fill="#282828"
      />
    </svg>
  )
}

IconSocialYoutube.propTypes = {
  height: string,
  width: string,
}
