import { string } from 'prop-types'

export default function IconSocialTumblr({ color, height = '48px', width = '48px' }) {
  return (
    <svg height={height} viewBox="0 0 512 512" width={width}>
      <path
        clipRule="evenodd"
        d="M22.6666 28H18.7331C15.1913 28 12.5516 26.143 12.5516 21.7004V14.5859H9.33331V10.7335C12.8751 9.79605 14.3565 6.68987 14.5272 4H18.205V10.1086H22.496V14.5859H18.205V20.7809C18.205 22.6376 19.1246 23.2795 20.5888 23.2795H22.6666V28Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

IconSocialTumblr.propTypes = {
  color: string.isRequired,
  height: string,
  width: string,
}
