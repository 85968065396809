import { string } from 'prop-types'

export default function IconArrowRight({ color, height = 512, width = 512 }) {
  return (
    <svg height={height} viewBox="0 0 512 512" width={width}>
      <polygon fill={color} points="160,115.4 180.7,96 352,256 180.7,416 160,396.7 310.5,256 " />
    </svg>
  )
}

IconArrowRight.propTypes = {
  color: string.isRequired,
  height: string,
  width: string,
}
