import { string } from 'prop-types'

export default function IconSocialFacebook({ color, height, width }) {
  return (
    <svg height={height} viewBox="0 0 32 32" width={width}>
      <path
        clipRule="evenodd"
        d="M28 16.0654C28 9.39767 22.6357 4 16.0093 4C9.38283 4 4 9.39767 4 16.0654C4 22.0981 8.39907 27.0848 14.1346 28V19.558H11.0719V16.0654H14.1346V13.4132C14.1346 10.3875 15.9165 8.70661 18.6636 8.70661C19.9814 8.70661 21.3364 8.96809 21.3364 8.96809V11.9191H19.8329C18.348 11.9191 17.8654 12.8529 17.8654 13.8054V16.0654H21.2065L20.6682 19.558H17.8654V28C23.6009 27.1035 28 22.0981 28 16.0654Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

IconSocialFacebook.propTypes = {
  color: string.isRequired,
  height: string,
  width: string,
}
